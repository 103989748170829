export const WORDS = [
  'revin',
  'fular',
  'perne',
  'prima',
  'falsa',
  'folie',
  'lupta',
  'titlu',
  'macar',
  'rahat',
  'crezi',
  'carpa',
  'rusul',
  'ursul',
  'gazda',
  'jupui',
  'coleg',
  'local',
  'viram',
  'vanat',
  'salut',
  'sport',
  'gonim',
  'traim',
  'goale',
  'nasii',
  'fixaj',
  'scame',
  'ireal',
  'frica',
  'caiet',
  'radem',
  'creta',
  'clama',
  'aspir',
  'linte',
  'zisul',
  'acest',
  'galop',
  'alias',
  'viata',
  'orase',
  'cotet',
  'pumni',
  'filat',
  'purec',
  'coate',
  'pasai',
  'uzata',
  'rulau',
  'dubiu',
  'ferit',
  'regie',
  'ghete',
  'video',
  'carau',
  'aliaj',
  'tenis',
  'cerem',
  'natie',
  'vinil',
  'verve',
  'rulou',
  'densa',
  'mambo',
  'crete',
  'tunsa',
  'presa',
  'barci',
  'burse',
  'frate',
  'barba',
  'sosit',
  'rufos',
  'ostil',
  'sunet',
  'ambar',
  'omizi',
  'opera',
  'repui',
  'legam',
  'retus',
  'minim',
  'tinem',
  'parem',
  'brate',
  'roata',
  'sepie',
  'bazat',
  'jeleu',
  'barul',
  'nitel',
  'geniu',
  'aurit',
  'sofer',
  'devin',
  'niste',
  'bobul',
  'sacou',
  'scuza',
  'vorbi',
  'miros',
  'nauca',
  'zambi',
  'optau',
  'cupon',
  'albim',
  'jetul',
  'capia',
  'pusca',
  'afirm',
  'carat',
  'harpa',
  'bufet',
  'gemut',
  'eroul',
  'arest',
  'masea',
  'ajuns',
]
